<template>
  <div>
    <div class="contentBox">
      <div class="title">
        <div class="titleText">
          <p
            style="background: #ff7727"
            class="diamond"
            v-if="header.is_quote == 0"
          >
            <span> 待报价</span>
          </p>
          <p v-if="header.is_quote == 1">
            报价来源：<span style="color: #ff7727"
              >{{ header.sales_info.sales_name_zh }}（Phone:{{
                header.sales_info.sales_mobile
              }}
              | Email:{{ header.sales_info.sales_email }}）</span
            >
          </p>
          <p
            style="background: #ccc"
            class="diamond"
            v-if="header.is_quote == 2"
          >
            <span>已过期</span>
          </p>
        </div>
        <div class="rightMsg">
          <div class="upDatatime" style="margin-right:30px;">
              更新时间：{{ (header.is_quote?header.created_time:header.created_time) | secondFormat("LL")}}
          </div>
          <div class="dingcang" v-if="header.is_quote == 1">
            <div>报价编号： {{ header.quotation_no | textFormat }}</div>
            <!-- <el-button
              v-if="USER_INFO&&USER_INFO.user_type==1"
              style="margin-left: 20px"
              class="booking"
              type="success"
              size="small"
              @click="handleBookingSpace"
              :loading="loading"
              >快速订舱</el-button
            > -->
          </div>
        </div>
      </div>
      <shipMsg v-loading="loading" :header='header' pageType='inquery' ></shipMsg>

      <div class="seaMoney">
        <p class="seaTitle">费用明细</p>
      </div>
      <div class="unnoMsg" v-if="header.is_dangerous">
        <div class="no">UNNO：{{ header.dangerous.un_no | textFormat }}</div>
        <div class="psn">PSN：{{ header.dangerous.pscn | textFormat }}</div>
        <div class="class">
          Class：{{ header.dangerous.classify | textFormat }}
        </div>
        <div class="PG">PG：{{ header.packing_group | textFormat }}</div>
      </div>
      <div class="moneyList" v-loading="loading">
        <div class="seaItem">
          <div class="itemTitleBox">
            <div>
              <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
              <span class="itemTitle">海运费：</span>
              <span class="itemMOney"
                >{{ header.currency }}

                <span
                  :class="
                    !header.is_open && !header.is_quote == 1 ? 'mohu' : 'price'
                  "
                  >{{ seaTotal }}</span
                ></span
              ><span class="itemTips"></span>
            </div>
          </div>
          <div class="itemContent">
            <div class="tableLine tableTitle">
              <div class="table1"></div>
              <div class="table2">币种</div>
              <div class="table3">计量单位</div>
              <div class="table4">单价</div>
            </div>
            <div
              class="tableLine"
              style="border-bottom: 1px solid #EBEEF5"
              v-for="(item, index) in header.container"
              :key="index"
              v-if="item.number && item.price!=0"
            >
              <div class="table1">海运费</div>
              <div class="table2">{{ header.currency }}</div>
              <div class="table3">{{ item.name }}</div>
              <div
                class="table4"
                :class="!header.is_open && !header.is_quote ? 'mohu' : 'price'"
              >
                {{ item.price }}
              </div>
            </div>
          </div>
        </div>
        <costComp :costList='costList' @open_close="open_close" ></costComp>

        <div class="page_tips">
          <i class="el-icon-warning"></i>
          <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseInfo from "~ymp/components/common/baseInfo";
import costComp from "~bac/components/ymp/costComp";
import shipMsg from "~bac/components/ymp/shipMsg";
export default {
  name: "freightSearchDetailsCFS",
  components:{baseInfo,costComp,shipMsg},
  data() {
    return {
      checkedArr: ["20GP"],
      header: {},
      loading: false,
      dialogVisible: false,
      costList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      unnoMsg: {},
    };
  },
  mounted() {
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    if (this.$route.query.unno) {
      this.unno = this.$route.query.unno;
    }
    this.getHeader();
  },
  computed: {},
  methods: {
    changeShow(item) {
      item.open = !item.open;
      console.log(this.costList);
      this.$forceUpdate();
    },
    // 子组件  -打开关闭
    open_close(e){
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },

    handleClose() {},
    async getHeader() {
      this.loading = true;

      let params = {
        inquiry_id: this.$route.query.inquiry_id,
      };

      let result = await Promise.all([
        this.$store.dispatch("baseConsole/getMyInquireInfo", params),
      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;
        console.log(data);
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");
        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        data.container = JSON.parse(data.container);
        let money = 0;
        data.container.forEach((ee) => {
          ee.price =Number(ee.price).toFixed()
          if (ee.number) {
            money = money*1 + ee.price*1;
          }
        });
        this.costList = JSON.parse(data.extend);

        this.costList.forEach((ee, index) => {
          ee.canCopy = true;
          ee.open = true;
          if (index > 0) {
            ee.open = false;
          }
        });
        if (data.is_quote == 0 && data.validity_end < this.$moment().unix()) {
          data.is_quote = 2;
        }
        this.seaTotal =Number(money).toFixed();
        this.header = data;
        this.effect_cost()
      }
    },
    // 选择箱型===>影响附加费  header.container
    effect_cost(){

      let arr=[]
        for( var dd of this.header.container ){
          if(dd.price!=0&&dd.number==1){
            arr.push(dd.name)
          }
        }
        console.log('aaaaaaa',arr);
        console.log('bbbbbbbbb',this.header.container);
      for(var item of this.costList){
        item.group_total_price={}
        for(var it of item.data_list){

          if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱
            for(var key of arr){
              if(key=='20GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP20)
                }else{
                  item.group_total_price[it.code]=Number(it.GP20)
                }
              }
              if(key=='40GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP40)
                }else{
                  item.group_total_price[it.code]=Number(it.GP40)
                }
              }
              if(key=="40HQ"){
                  if(item.group_total_price[it.code]){
                    item.group_total_price[it.code]+=Number(it.HC40)
                  }else{
                    item.group_total_price[it.code]=Number(it.HC40)
                  }
              }
            }
          }else if(it.code==""){  // 没有币种得不计算

          }else{  // 票
            if(item.group_total_price[it.code]){
              item.group_total_price[it.code]+=Number(it.price)
            }else{
              item.group_total_price[it.code]=Number(it.price)
            }
          }
        }
      }
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    handleBookingSpace() {

      this.$router.push({
        path: "/FclbookingSpace",
        query: {

          id:  this.$route.query.inquiry_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page_tips{
  padding:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.diamond {
  transform: skew(-20deg);
  color: #fff;
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  span {
    display: block;
    transform: skew(20deg);
  }
}
.lastTips {
  font-size: 14px;
  color: #000;
  font-weight: 900;
  display: flex;
  align-items: center;
  i {
    color: #ff7727;
    font-size: 25px;
    margin-right: 10px;
  }
}
.contentBox {
  padding: 0 30px;
}
.rightMsg{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dingcang {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.unnoMsg {
  display: flex;
  padding: 0 30px;
  align-items: center;
  .no {
    width: 200px;
    margin-right: 50px;
  }
  .psn {
    max-width: 600px;
    margin-right: 150px;
  }
  .class {
    width: 200px;
    margin-right: 50px;
  }
  .PG {
    width: 200px;
  }
}
.unno {
  display: flex;
  align-items: center;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.moneyList {
  background: #fff;
  padding: 20px;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 25px;
      height: 25px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaMoney {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
  }
  .searchBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  min-height: 83vh;
}
.title {
  display: flex;
  height: 88px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  .titleText {
    font-weight: 800;
    color: #1f292e;
    font-size: 16px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.header {
  background: #fff;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;
    .div1 {
      max-width: 250px;
    }
    .div2 {
      max-width: 250px;
      margin: 0 20px;
    }
    .div3 {
      max-width: 250px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      min-width: 150px;
      max-width: 350px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
      margin: 0 20px;
    }
    .div1 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div2 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div3 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div4 {
      width: 300px;
      color: rgba(0, 0, 0, 0.65);
      overflow: visible;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
</style>
